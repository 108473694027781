import {FormOverride} from '@wix/form-viewer/dist/types/services/form-overrides';
import {FormError, FormViewerHandle} from '@wix/form-viewer/widget';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {mergeDeep} from '../../../domain/utils/mergeDeep';
import {FormOverrides} from '../../../types/checkoutApp.types';
import {FormValues, FullNameValue, MultilineAddressValue} from '@wix/form-fields';
import {AddressModel} from '../../../domain/models/Address.model';
import {DEFAULT_COUNTRY} from '../constants';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../../gql/graphql';
import {ContactModel} from '../../../domain/models/Contact.model';

export const mandatoryOverride: FormOverride = {
  hidden: false,
  required: true,
};

export interface FormProps {
  formRef: React.MutableRefObject<FormViewerHandle | null>;
  formValues: FormValues;
  setFormValues: (value: ((prevState: FormValues) => FormValues) | FormValues) => void;
  formErrors: FormError[];
  setFormErrors: (value: ((prevState: FormError[]) => FormError[]) | FormError[]) => void;
}

export function mergeOverrides(...overrides: FormOverrides[]): FormOverrides {
  return mergeDeep(...(overrides as {[key: string]: object}[]));
}

export function getSettingsOverrides({
  checkoutSettings,
}: {
  checkoutSettings: Pick<CheckoutSettingsModel, 'isLocalDeliveryByZipCodeEnabled'>;
}): FormOverrides {
  return {
    address: {
      postal_code: checkoutSettings.isLocalDeliveryByZipCodeEnabled ? mandatoryOverride : {},
    },
  };
}

export function getHideCountryOverride(): FormOverrides {
  return {address: {country: {hidden: true, required: false}}};
}

export function getDeliveryCountriesOverrides(
  enabledDeliveryCountries: CheckoutSettingsModel['enabledDeliveryCountries']
): FormOverrides {
  if (enabledDeliveryCountries.length) {
    return {address: {country: {countries: enabledDeliveryCountries}}};
  }
  return {};
}

export function getExcludeGoogleAutoCompleteOverrides({
  excludeGoogleAutoComplete,
}: {
  excludeGoogleAutoComplete?: boolean;
}): FormOverrides {
  return {
    address: {
      address_line: {
        autocomplete: !excludeGoogleAutoComplete,
      },
      street_name: {
        autocomplete: !excludeGoogleAutoComplete,
      },
    },
  };
}

export function getLabelsOverrides({localeKeys}: {localeKeys: ILocaleKeys}): FormOverrides {
  return {
    full_name: {
      first_name: {label: localeKeys.checkout.address_information.first_name_label()},
      last_name: {label: localeKeys.checkout.address_information.last_name_label()},
    },
    phone: {label: localeKeys.checkout.address_information.phone_number_label()},
    company_name: {label: localeKeys.checkout.address_information.company_label()},
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getAddressFormInitialState(address?: AddressModel, country?: string): FormValues {
  const subdivision = address?.subdivision?.includes('-') ? address?.subdivision?.split('-')[1] : address?.subdivision;

  return {
    address: {
      country,
      city: address?.city ?? '',
      ...(subdivision ? {subdivision} : {}),
      ...(address?.postalCode ? {postal_code: address.postalCode} : {}),
      ...(address?.addressLine ? {address_line: address.addressLine} : {}),
      ...(address?.streetAddress?.name ? {street_name: address.streetAddress.name} : {}),
      ...(address?.streetAddress?.number ? {street_number: address.streetAddress.number} : {}),
      ...(address?.addressLine2 ? {address_line_2: address?.addressLine2} : {}),
    },
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getAddressFromAddressFormValues(addressFormValues: FormValues): ApiAddressFragment {
  const address = addressFormValues.address as MultilineAddressValue;
  const streetAddress =
    address.street_name || address.street_number
      ? {
          name: (address.street_name as string) || /* istanbul ignore next: test forms */ undefined,
          number: (address.street_number as string) || /* istanbul ignore next: test forms */ undefined,
        }
      : undefined;

  return {
    country: address.country as string,
    city: address.city as string,
    ...(address.subdivision
      ? {
          subdivision: `${address.country as string}-${address.subdivision}`,
        }
      : {}),
    ...(address.postal_code ? {postalCode: address.postal_code} : /* istanbul ignore next: test forms */ {}),
    ...(address.address_line ? {addressLine: address.address_line} : {}),
    ...(streetAddress ? {streetAddress} : {}),
    ...(address.address_line_2 ? {addressLine2: address.address_line_2} : {}),
  };
}

export function getCountryFromAddressFormValues(addressFormValues: FormValues): string {
  const address = addressFormValues.address as MultilineAddressValue;
  return address.country as string;
}

export function isSubdivisionsEqual(subdivision1?: string | null, subdivision2?: string | null): boolean {
  if (subdivision2 && subdivision1) {
    return subdivision1.includes(subdivision2) || /* istanbul ignore next */ subdivision2.includes(subdivision1);
  }

  return subdivision1 === subdivision2;
}

export function getContactFormInitialState({contact, country}: {contact?: ContactModel; country?: string}): FormValues {
  return {
    full_name: {
      first_name: contact?.firstName ?? '',
      last_name: contact?.lastName ?? '',
      country: country ?? /* istanbul ignore next */ DEFAULT_COUNTRY,
    },
    ...{phone: contact?.phone ?? ''},
    ...{company_name: contact?.company ?? ''},
  };
}

export function getContactFormCurrentStateWithUpdatedCountry({
  contactFormValues,
  country,
}: {
  contactFormValues: FormValues;
  country?: string;
}): FormValues {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    ...contactFormValues,
    full_name: {
      ...fullName,
      country,
    },
  };
}

export function getContactDetailsFromContactFormValues(
  contactFormValues: FormValues
): FullAddressContactDetailsFragment {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    firstName: fullName.first_name as string,
    lastName: fullName.last_name as string,
    ...(contactFormValues.phone ? {phone: contactFormValues.phone as string} : {}),
    ...(contactFormValues.company_name ? {company: contactFormValues.company_name as string} : {}),
  };
}
